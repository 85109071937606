// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-automatisk-bogholderi-js": () => import("./../../../src/pages/automatisk-bogholderi.js" /* webpackChunkName: "component---src-pages-automatisk-bogholderi-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-job-js": () => import("./../../../src/pages/job.js" /* webpackChunkName: "component---src-pages-job-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mail-sendt-js": () => import("./../../../src/pages/mail-sendt.js" /* webpackChunkName: "component---src-pages-mail-sendt-js" */),
  "component---src-pages-moed-vores-medarbejdere-js": () => import("./../../../src/pages/moed-vores-medarbejdere.js" /* webpackChunkName: "component---src-pages-moed-vores-medarbejdere-js" */),
  "component---src-pages-nyheder-js": () => import("./../../../src/pages/nyheder.js" /* webpackChunkName: "component---src-pages-nyheder-js" */),
  "component---src-pages-om-os-js": () => import("./../../../src/pages/om-os.js" /* webpackChunkName: "component---src-pages-om-os-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-accounting-js": () => import("./../../../src/templates/accounting.js" /* webpackChunkName: "component---src-templates-accounting-js" */),
  "component---src-templates-cases-js": () => import("./../../../src/templates/cases.js" /* webpackChunkName: "component---src-templates-cases-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-sub-page-js": () => import("./../../../src/templates/subPage.js" /* webpackChunkName: "component---src-templates-sub-page-js" */)
}

